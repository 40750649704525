export enum FirestoreCollection {
  RunStatus = "runStatus",
  InstantiationCenter = "instantiationCenter"
}

export type RunStatus = {
  status: "success" | "fail" | "running" | "unknown";
  avgRuntime?: number;
  runCount?: number;
};
