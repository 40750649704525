import Sidebar, { SidebarToggleButton } from "components/common/Sidebar";
import { ToastManager } from "components/managers/ToastManager";
import { queryClient, queryKeyMap } from "data/query.data";
import { routeList, routeMap } from "data/routeList";
import { useStoreRehydrated } from "easy-peasy";
import { RunStatus } from "models/firestore.model";
import { Suspense, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "stores";
import "styles/main/App.sass";
import "styles/main/theme.sass";
import { initializeIconList } from "utils/iconInitializer";

initializeIconList();

function App() {
  const isHydrated = useStoreRehydrated();

  const isLoggedIn = useStoreState((state) => state.account.isLoggedIn);
  const { initializeStore } = useStoreActions((actions) => actions.account);

  const { initializeTheme } = useStoreActions((actions) => actions.theme);
  const { initializeStore: initializeABMStore } = useStoreActions(
    (actions) => actions.abm,
  );

  useEffect(() => {
    // Set up the initial theme
    //  currently default to light theme
    //  (you can change it in ThemeStore.jsx)
    initializeTheme();
  }, [initializeTheme]);

  useEffect(() => {
    initializeStore();
  }, [initializeStore]);

  useEffect(() => {
    if (isLoggedIn) {
      initializeABMStore();
    }
  }, [isLoggedIn, initializeABMStore]);

  // * Run status
  const prevStatus = useRef<RunStatus | null>(null);
  const singleRunStatus = useStoreState((state) => state.abm.singleRunStatus);

  const { setToastMessage } = useStoreActions(
    (actions) => actions.toastMessage,
  );

  useEffect(() => {
    if (prevStatus.current != null && singleRunStatus !== prevStatus.current) {
      if (
        prevStatus.current.status === "running" &&
        singleRunStatus.status === "success"
      ) {
        queryClient.invalidateQueries(queryKeyMap.siteData);

        // * Allow the user to download
        setToastMessage({
          message: "Finished executing model. Please refresh the page",
          type: "success",
        });
      }
    }

    prevStatus.current = singleRunStatus;
  }, [singleRunStatus, setToastMessage]);

  return (
    <Router>
      <div className="app">
        <Sidebar />
        <SidebarToggleButton />

        <div className="content-container">
          <Suspense fallback={<div>Loading</div>}>
            {isHydrated ? (
              <Switch>
                {routeList.map(
                  (
                    { path, Component, text, showHeader, requireLoggedIn },
                    index,
                  ) => (
                    <Route path={path} key={index} exact>
                      {(requireLoggedIn && isLoggedIn) ||
                      (!requireLoggedIn && !isLoggedIn) ? (
                        <>
                          {showHeader && <h2 className="header">{text}</h2>}
                          <Component />
                        </>
                      ) : (
                        <Redirect
                          to={isLoggedIn ? routeMap.dashboard : routeMap.login}
                        />
                      )}
                    </Route>
                  ),
                )}
              </Switch>
            ) : (
              <div>Loading</div>
            )}
          </Suspense>
        </div>
      </div>

      <ToastManager />
    </Router>
  );
}

export default App;
