import { ToastOptions } from "react-toastify";

export type ToastType = "warning" | "success" | "error" | "info" | "loading";
export interface ToastMessageObjectType {
  message: string | Array<string>;
  type: ToastType;
}

export const loadingToastId = "loading-toast";
export const toastOptionPreset: Partial<Record<ToastType, ToastOptions>> = {
  loading: {
    autoClose: false,
    toastId: loadingToastId,
    bodyClassName: "loading-toast-body",
  },
  error: { autoClose: 10000 },
};
