import { createStore, createTypedHooks } from "easy-peasy";

import {
  SidebarModel,
  sidebar,
  ThemeModel,
  theme,
  ToastModel,
  toastMessage,
} from "./misc";
import { ABMModel, abm, UserInputModel, userInput } from "./ABM";
import { AccountModel, account } from "./auth";

export interface StoreModel {
  abm: ABMModel;
  userInput: UserInputModel;

  sidebar: SidebarModel;
  theme: ThemeModel;
  toastMessage: ToastModel;

  account: AccountModel;
}

const model: StoreModel = {
  abm,
  userInput,

  sidebar,
  theme,
  toastMessage,

  account,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();

export const store = createStore(model);
export { useStoreState, useStoreActions };
