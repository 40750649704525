import { Action, action } from "easy-peasy";

export interface ThemeModel {
  // * State -------------------------
  theme: "theme-dark" | "theme-light";

  // * Actions -----------------------
  toggleTheme: Action<ThemeModel>;
  initializeTheme: Action<ThemeModel>;
  changeTheme: Action<ThemeModel, "theme-dark" | "theme-light">;
}

export const theme: ThemeModel = {
  theme: "theme-light",

  //Actions
  toggleTheme: action((state) => {
    document.body.classList.remove(state.theme);
    state.theme = state.theme === "theme-light" ? "theme-dark" : "theme-light";
    document.body.classList.add(state.theme);
  }),

  initializeTheme: action((state) => {
    document.body.classList.add(state.theme);
  }),

  changeTheme: action((state, newTheme) => {
    document.body.classList.remove(state.theme);
    state.theme = newTheme;
    document.body.classList.add(state.theme);
  }),
};

export default theme;
