import { toastOptionPreset, ToastType } from "data/toast.info";
import { toast, ToastOptions } from "react-toastify";

export const toastCustomMessage = (
  message: string,
  type: ToastType,
  options?: ToastOptions
) =>
  toast(message, {
    ...toastOptionPreset[type],
    ...options,
  });
