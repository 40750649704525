import { Action, action } from "easy-peasy";

export interface SidebarModel {
  // * State
  isOpen: boolean;

  // * Actions
  toggleIsOpen: Action<SidebarModel>;
  changeIsOpen: Action<SidebarModel, boolean>;
}

export const sidebar: SidebarModel = {
  isOpen: false,

  //Actions
  toggleIsOpen: action((state) => {
    state.isOpen = !state.isOpen;
  }),
  changeIsOpen: action((state, shown) => {
    state.isOpen = shown;
  }),
};

export default sidebar;
