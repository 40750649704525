import { Action, action, persist, Thunk, thunk } from "easy-peasy";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { StoreModel } from "stores/StoreFront";
import { firebaseApp } from "utils/firebase.util";
import {
  getErrorMessageDetail,
  getErrorResponse,
  getFirebaseError,
} from "utils/utils";

export interface AccountModel {
  // * State
  isLoggedIn: boolean;

  // * Actions
  setIsLoggedIn: Action<AccountModel, boolean>;

  // * Thunk
  initializeStore: Thunk<AccountModel>;
  login: Thunk<
    AccountModel,
    { email: string; password: string },
    any,
    StoreModel
  >;
  logout: Thunk<AccountModel, void, any, StoreModel>;
}

export const account: AccountModel = persist({
  // * State
  isLoggedIn: false,

  // * Action
  setIsLoggedIn: action((state, isLoggedIn) => {
    state.isLoggedIn = isLoggedIn;
  }),

  // * Thunk
  initializeStore: thunk((actions) => {
    getAuth(firebaseApp).onAuthStateChanged((user) => {
      actions.setIsLoggedIn(user != null);
    });
  }),
  login: thunk(async (actions, { email, password }, { getStoreActions }) => {
    try {
      console.info("Logging in...");
      await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);

      actions.setIsLoggedIn(true);

      getStoreActions().toastMessage.setToastMessage({
        message: "Login successfully!",
        type: "success",
      });
    } catch (error) {
      const firebaseError = getFirebaseError(error);
      let userMessage = getErrorMessageDetail(error);

      switch (firebaseError.code) {
        case "auth/user-not-found":
          userMessage = "User not found, please check your email and password";
          break;
      }

      getStoreActions().toastMessage.setToastMessage({
        message: `Error when logging in: ${userMessage}`,
        type: "error",
      });
    }
  }),
  logout: thunk(async (actions, _, { getStoreActions }) => {
    try {
      await getAuth(firebaseApp).signOut();

      // * Clear all old data
      getStoreActions().abm.resetStore();

      actions.setIsLoggedIn(false);
      getStoreActions().toastMessage.setToastMessage({
        message: "Logout successfully!",
        type: "success",
      });
    } catch (error) {
      console.error(getErrorResponse(error));
      getStoreActions().toastMessage.setToastMessage({
        message: `Error when logging out: ${getErrorMessageDetail(error)}`,
        type: "error",
      });
    }
  }),
});
