import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { sleep } from "./api.util";
import { toastCustomMessage } from "./ui.util";

const firebaseConfig = {
  apiKey: "AIzaSyBP9Mcjes4OXzbRSwNC8sR6lNsZX4AyNaQ",
  authDomain: "in-site-housing.firebaseapp.com",
  projectId: "in-site-housing",
  storageBucket: "in-site-housing.appspot.com",
  messagingSenderId: "277569727708",
  appId: "1:277569727708:web:be8205970648c9c23ad819",
  measurementId: "G-61LR28CT1M",
};

export const firebaseApp = initializeApp(firebaseConfig);

export const getFirebaseUserToken = async (): Promise<string> => {
  let token = await getFirebaseUser()?.getIdToken();

  const MAX_RETRY = 10;
  let counter = 0;
  for (; counter < MAX_RETRY && !token; counter++) {
    // try again first
    await sleep(500);
    token = await getFirebaseUser()?.getIdToken();
  }

  if (counter > 0) {
    console.warn(`Account load after ${counter} retries`);
  }
  if (!token) {
    toastCustomMessage(
      "Fail to load your account, please refresh or check your network",
      "error",
    );
    throw new Error(`Fail to load account after ${counter} retries`);
  }

  return token;
};

export const getFirebaseUser = () => getAuth(firebaseApp).currentUser;
