import { Coordinate, UserInput } from "data/common.info";
import {
  Action,
  action,
  Computed,
  computed,
  persist,
  Thunk,
  thunk,
} from "easy-peasy";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { FirestoreCollection } from "models/firestore.model";
import { firebaseApp } from "utils/firebase.util";

export interface UserInputModel {
  storedUserInput: Partial<UserInput> | null;
  centerCoord: Computed<UserInputModel, Coordinate>;

  setUserInput: Action<UserInputModel, Partial<UserInput>>;
  setPartialUserInput: Action<UserInputModel, Partial<UserInput>>;
  resetUserInput: Action<UserInputModel>;

  setupCoordListener: Thunk<UserInputModel>;
}

export const userInput: UserInputModel = persist(
  {
    storedUserInput: {},
    centerCoord: computed((state) => ({
      lat: state.storedUserInput?.lat || 0,
      lon: state.storedUserInput?.lon || 0,
    })),

    setUserInput: action((state, data) => {
      state.storedUserInput = data;
    }),
    setPartialUserInput: action((state, data) => {
      if (state.storedUserInput) {
        state.storedUserInput = {
          ...state.storedUserInput,
          ...data,
        };
      } else {
        state.storedUserInput = data;
      }
    }),
    resetUserInput: action((state) => {
      state.storedUserInput = null;
    }),

    setupCoordListener: thunk((actions) => {
      const db = getFirestore(firebaseApp);
      onSnapshot(
        doc(db, FirestoreCollection.InstantiationCenter, "coord"),
        (doc) => {
          const coord = doc.data() as Coordinate | undefined;
          if (coord) {
            actions.setPartialUserInput(coord);
          }
        },
      );
    }),
  },
  {
    storage: "localStorage",
  },
);
