import _ from "lodash";

export const transformKey = (key: string) =>
  _.upperFirst(_.replace(key, /(_|-)/, " "));

export const getRandomColor = () =>
  "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);

export const getFrequencyBins = (
  valueList: Array<number>,
  binCount: number
) => {
  let min = valueList[0];
  let max = valueList[0];
  valueList.forEach((value) => {
    if (min > value) {
      min = value;
    } else if (max < value) {
      max = value;
    }
  });

  const interval = Math.ceil((max - min + 1) / binCount);

  // Create a freq map to temporarily utilize index as bin number
  const freqArr: Array<number> = [];
  for (let i = 0; i < binCount; i++) {
    freqArr.push(0);
  }

  // Map each value to the corresponding bin
  valueList.forEach((value) => {
    freqArr[Math.floor((value - min) / interval)]++;
  });

  // Transform the array to map
  const freqMap: Record<string, number> = {};
  freqArr.forEach((value, index) => {
    freqMap[Math.floor(min + index * interval)] = value;
  });
  return freqMap;
};

export const getErrorResponse = (error: any) =>
  error?.response ||
  JSON.stringify(error?.toJSON?.()) ||
  JSON.stringify(error, Object.getOwnPropertyNames(error));

export const getErrorMessageDetail = (error: any) =>
  getErrorResponse(error).data?.detail ||
  error?.message ||
  JSON.stringify(error?.toJSON?.()) ||
  JSON.stringify(error);

export const getFirebaseError = (error: any) => ({
  code: error.code,
  message: error.message,
});
