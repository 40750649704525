import { isInDev } from "utils/env.util";

const resourceLocationList = [
  "none/",
  "http://127.0.0.1:8080/",
  "https://insite-housing.herokuapp.com/",
] as const;

const resourceLocation = isInDev()
  ? resourceLocationList[1]
  : resourceLocationList[2];

// * General endpoint information

const baseEndpointList = {
  startSingle: "single/start",
  startBatch: "batch/start", // Init and run batch at the same time

  restoreInput: "restore/input",
} as const;
export type EndpointKeyList = keyof typeof baseEndpointList;

// Transform url
const endpointList: Record<EndpointKeyList, string> | Record<string, string> =
  {};
Object.entries(baseEndpointList).forEach(([key, endpoint]) => {
  endpointList[key as EndpointKeyList] = resourceLocation + endpoint;
});

export default endpointList;

// Transform url
export const downloadEndpointList = {
  downloadResultModelData: `${resourceLocation}download/result/model`,
  downloadResultAgentData: `${resourceLocation}download/result/agent`,
  downloadResultStaleholderData: `${resourceLocation}download/result/stakeholder`,
  downloadResultRegulatorData: `${resourceLocation}download/result/regulator`,
  downloadResultBatchModelData: `${resourceLocation}download/result/batch/model`,
  downloadResultBatchAgentData: `${resourceLocation}download/result/batch/agent`,

  downloadHistoricalData: `${resourceLocation}download/historical`,
  downloadPropOutOutline: `${resourceLocation}download/property/out/outline`,

  downloadCitOutShape: `${resourceLocation}download/cit_out_shape`,
  downloadStakeholder: `${resourceLocation}download/stakeholders`,
  downloadRegulator: `${resourceLocation}download/regulators`,
  downloadPowerline: `${resourceLocation}download/powerline`,
  downloadBGOutline: `${resourceLocation}download/blockgroup/outline`,
  downloadBGData: `${resourceLocation}download/blockgroup/data`,

  downloadPropOutline: `${resourceLocation}download/property/in/outline`,
  downloadPropData: `${resourceLocation}download/property/in/data`,
  downloadInstantiationCenter: `${resourceLocation}download/instantiation/center`,
} as const;

export const uploadEndpointList = {
  uploadStakeholders: `${resourceLocation}upload/stakeholders`,
  uploadRegulators: `${resourceLocation}upload/regulators`,
  uploadBGOutline: `${resourceLocation}upload/blockgroup/outline`,
  uploadBGData: `${resourceLocation}upload/blockgroup/data`,
  uploadPowerline: `${resourceLocation}upload/powerline`,

  uploadPropOutline: `${resourceLocation}upload/property/in/outline`,
  uploadPropData: `${resourceLocation}upload/property/in/data`,
} as const;

// * Endpoint return types
export interface SingleExecutionReturnBody {
  model_output: Array<string>;
  property_output: Array<string>;
  outline_output: Array<string>;
}
