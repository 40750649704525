import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useMedia, useClickAway } from "react-use";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import "styles/components/common/Sidebar.sass";

import { useStoreState, useStoreActions } from "stores";
import Icon from "./Icon";
import { routeList } from "data/routeList";

import logo from "assets/images/logo.jpg";

const textTransitionDuration = 500;

export const SidebarToggleButton = () => {
  const isLg = useMedia("(min-width: 1024px)");
  const { isOpen } = useStoreState((state) => state.sidebar);
  const { toggleIsOpen } = useStoreActions((actions) => actions.sidebar);

  const renderCondition = !isLg && !isOpen;
  return renderCondition ? (
    <button className="sidebar-toggle-button" onClick={() => toggleIsOpen()}>
      <Icon icon={["fas", "bars"]} />
    </button>
  ) : null;
};

const Sidebar = () => {
  const { isLoggedIn } = useStoreState((state) => state.account);
  const { logout } = useStoreActions((actions) => actions.account);

  const { isOpen } = useStoreState((state) => state.sidebar);
  const { toggleIsOpen, changeIsOpen } = useStoreActions(
    (actions) => actions.sidebar
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => changeIsOpen(false));

  const renderItemInner = (icon: Array<string>, text: string) => (
    <div className="item-inner-container">
      <Icon icon={icon} />

      <div className="text-wrapper">
        <CSSTransition
          in={isOpen}
          timeout={textTransitionDuration}
          classNames="sidebar-fade-2"
          unmountOnExit
        >
          <p className="text">{text}</p>
        </CSSTransition>
      </div>
    </div>
  );

  return (
    <>
      <div
        ref={containerRef}
        className={cx("sidebar", { "sidebar-open": isOpen })}
      >
        <div className={cx("logo", { "logo-open": isOpen })}>
          <img src={logo} alt="logo" />
        </div>

        <nav className="item-container">
          {routeList.map(
            ({ icon, path, text, requireLoggedIn }, index) =>
              ((requireLoggedIn && isLoggedIn) ||
                (!requireLoggedIn && !isLoggedIn)) && (
                <NavLink
                  exact
                  to={path}
                  key={index}
                  activeClassName="item-active item-link-active"
                  className={cx("item", { "item-open": isOpen })}
                >
                  {renderItemInner(icon, text)}
                </NavLink>
              )
          )}
        </nav>

        <div className="bottom-item-container">
          {isLoggedIn && (
            <button className="item" onClick={() => logout()}>
              {renderItemInner(["fas", "sign-out-alt"], "Logout")}
            </button>
          )}

          <button onClick={() => toggleIsOpen()} className="item item-caret">
            <div className="item-inner-container">
              <Icon
                className={cx("caret fa-fw", { "caret-open": isOpen })}
                icon={["fas", "angle-right"]}
              />
            </div>
          </button>
        </div>
      </div>

      <div
        className={cx("sidebar-overlay", { "sidebar-overlay-show": isOpen })}
      />
    </>
  );
};

export default Sidebar;
