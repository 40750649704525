import { useEffect } from "react";
import { toast } from "react-toastify";

import { useStoreState } from "stores";

export const ToastManager = () => {
  const { toastMessage } = useStoreState((state) => state.toastMessage);

  // Toast whenever we have new message
  useEffect(() => {
    if (toastMessage != null) {
      const type = toastMessage.type;
      let icon = "⚠️";
      switch (type) {
        case "warning":
          icon = "⚠️";
          break;
        case "success":
          icon = "🎉";
          break;
        case "error":
          icon = "🔺";
          break;
        case "info":
          icon = "ℹ️";
          break;
      }
      const message = `${icon} ${toastMessage.message}`;
      toast(message, { type });
    }
  }, [toastMessage]);

  return null;
};
