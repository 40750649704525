import { lazy, LazyExoticComponent } from "react";

const Dashboard = lazy(() => import("pages/Dashboard"));
const Stats = lazy(() => import("pages/Stats"));
const MapPage = lazy(() => import("pages/MapPage"));
const LoginPage = lazy(() => import("pages/LoginPage"));

export const routeMap = {
  dashboard: "/",
  login: "/login",
  map: "/map",
  stats: "/stats",
} as const;
export interface RouteProp {
  icon: Array<string>;
  path: typeof routeMap[keyof typeof routeMap];
  text: string;
  Component: LazyExoticComponent<() => JSX.Element>;
  showHeader?: boolean;
  requireLoggedIn?: boolean;
}

export const routeList: Array<RouteProp> = [
  {
    text: "Dashboard",
    Component: Dashboard,
    path: routeMap.dashboard,
    icon: ["fas", "tachometer-alt"],
    showHeader: true,
    requireLoggedIn: true,
  },
  {
    text: "Login",
    Component: LoginPage,
    path: routeMap.login,
    icon: ["fas", "sign-in-alt"],
    showHeader: true,
  },
  {
    text: "Map",
    Component: MapPage,
    path: routeMap.map,
    icon: ["fas", "map"],
    requireLoggedIn: true,
  },
  {
    text: "Stats",
    Component: Stats,
    path: routeMap.stats,
    icon: ["fas", "signal"],
    showHeader: true,
    requireLoggedIn: true,
  },
];
