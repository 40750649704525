import axios, { AxiosRequestConfig, Method } from "axios";
import { getFirebaseUserToken } from "utils/firebase.util";
import lodash from "lodash";

interface SendRequestProps {
  endpointInfo: {
    endpoint: string;
    method: Method;
  };
  data?: any;
  useToken?: boolean;
  otherConfig?: AxiosRequestConfig;
}

export async function sendRequest<T extends any>({
  endpointInfo,
  data,
  useToken = true,
  otherConfig,
}: SendRequestProps) {
  const headers: Record<string, any> = {};
  const params: Record<string, any> = {};

  if (useToken) {
    const token = await getFirebaseUserToken();
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await axios(
    lodash.merge(
      {
        baseURL: endpointInfo.endpoint,
        params,
        headers,
        method: endpointInfo.method,
        data,
      },
      otherConfig
    )
  );

  return response.data as T;
}

export async function downloadFile({
  endpointInfo,
  data,
  useToken,
  otherConfig,
  fileName,
}: SendRequestProps & { fileName: string }) {
  const file = await sendRequest<any>({
    endpointInfo,
    data,
    useToken,
    otherConfig: {
      method: "GET",
      responseType: "blob",
      headers: {
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      ...otherConfig,
    },
  });

  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}
