import { sendRequest } from "apis";
import { Coordinate } from "data/common.info";
import endpointList from "data/endpoints";
import { Thunk, thunk } from "easy-peasy";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { FirestoreCollection, RunStatus } from "models/firestore.model";
import { StoreModel } from "stores";
import { firebaseApp } from "utils/firebase.util";
import { getErrorMessageDetail, getErrorResponse } from "utils/utils";
import { ABMModel } from "./ABMStore";

export interface ABMStoreThunk {
  // * Thunks
  initializeStore: Thunk<ABMModel>;
  startModel: Thunk<ABMModel, object, any, StoreModel>; // Single run
  startBatch: Thunk<
    ABMModel,
    { acceptableRange: number; center: Coordinate },
    any,
    StoreModel
  >; // Batch run
  uploadFile: Thunk<
    ABMModel,
    { file: File; endpoint: string },
    any,
    StoreModel
  >;
}

export const abmStoreThunk: ABMStoreThunk = {
  initializeStore: thunk((actions) => {
    const db = getFirestore(firebaseApp);
    onSnapshot(doc(db, FirestoreCollection.RunStatus, "batchRun"), (doc) => {
      const status = doc.data() as RunStatus | undefined;
      actions.setBatchRunStatus(status || { status: "unknown" });
    });

    onSnapshot(doc(db, FirestoreCollection.RunStatus, "singleRun"), (doc) => {
      const status = doc.data() as RunStatus | undefined;
      actions.setSingleRunStatus(status || { status: "unknown" });
    });
  }),

  // * Thunks
  startModel: thunk(async (actions, userInput, { getStoreActions }) => {
    try {
      // * Driving model
      const body = userInput;
      await sendRequest<any>({
        endpointInfo: {
          endpoint: endpointList.startSingle,
          method: "POST",
        },
        data: body,
      });
    } catch (error) {
      console.error(getErrorResponse(error));
      getStoreActions().toastMessage.setToastMessage({
        message: `Error while executing model: ${getErrorMessageDetail(error)}`,
        type: "error",
      });
    }
  }),
  startBatch: thunk(
    async (actions, { acceptableRange, center }, { getStoreActions }) => {
      try {
        // Run batch
        await sendRequest<any>({
          endpointInfo: {
            endpoint: endpointList.startBatch,
            method: "POST",
          },
          data: { batch_acceptable_range: acceptableRange, center: center },
        });

        getStoreActions().toastMessage.setToastMessage({
          message: "Finish executing batch",
          type: "success",
        });
      } catch (error) {
        console.error(getErrorResponse(error));
        getStoreActions().toastMessage.setToastMessage({
          message: `Error while executing batch: ${getErrorMessageDetail(
            error,
          )}`,
          type: "error",
        });
      }
    },
  ),

  uploadFile: thunk(
    async (actions, { file, endpoint }, { getStoreActions }) => {
      actions.setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append("file", file);

        await sendRequest<any>({
          endpointInfo: { endpoint, method: "POST" },
          data: formData,
          otherConfig: { headers: { "Content-Type": "multipart/form-data" } },
        });

        getStoreActions().toastMessage.setToastMessage({
          message: "Finish uploading file",
          type: "success",
        });
      } catch (error) {
        console.error(getErrorResponse(error));
        getStoreActions().toastMessage.setToastMessage({
          message: `Error while uploading file: ${getErrorMessageDetail(
            error,
          )}`,
          type: "error",
        });
      } finally {
        actions.setIsUploading(false);
      }
    },
  ),
};
