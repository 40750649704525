import { library } from "@fortawesome/fontawesome-svg-core";
import * as fas from "@fortawesome/free-solid-svg-icons";
// import * as far from "@fortawesome/free-regular-svg-icons";

export const initializeIconList = () => {
  const iconList = [
    fas.faTachometerAlt,
    fas.faAngleRight,
    fas.faMap,
    fas.faSignal,
    fas.faBars,
    fas.faSpinner,
    fas.faCheck,
    fas.faUsers,
    fas.faSignOutAlt,
    fas.faSignInAlt,
    fas.faExclamationCircle,

    // * User input
    fas.faKeyboard,
    fas.faPlay,
    fas.faCloudDownloadAlt,
    fas.faHistory,
    fas.faFile,

    // * Data Table
    fas.faSortAmountDown,
    fas.faSortAmountDownAlt,
    fas.faSort,
    fas.faAngleDoubleLeft,
    fas.faChevronLeft,
    fas.faChevronRight,
    fas.faAngleDoubleRight,
    fas.faTable,

    // * Graph
    fas.faChartPie,
  ];

  // Set up icon libraries
  library.add(...iconList);
};
