import { Action, action } from "easy-peasy";

import { ToastType } from "data/toastInfo";

export interface ToastModel {
  toastMessage: { message: string; type: ToastType } | null;
  setToastMessage: Action<ToastModel, { message: string; type: ToastType }>;
}

// Use this to watch for new toast message
export const toastMessage: ToastModel = {
  toastMessage: null,
  setToastMessage: action((state, payload) => {
    state.toastMessage = payload;
  }),
};
