import { Action, action, Computed, computed } from "easy-peasy";
import { RunStatus } from "models/firestore.model";
import { abmStoreThunk, ABMStoreThunk } from "./ABMStore.thunk";

const checkCanDownloadDataForStatus = (runStatus: RunStatus) =>
  runStatus.status !== "running";

export interface ABMModel extends ABMStoreThunk {
  // * State
  // ----- Server state
  canDownloadData: Computed<ABMModel, boolean>;
  singleRunStatus: RunStatus; // For single run
  batchRunStatus: RunStatus; // For batch run

  isUploading: boolean; // Uploading file state
  // ----- Graphing
  graphModelKey: string | null;
  graphAgentKey: string | null;

  // * Actions
  resetStore: Action<ABMModel>;
  // ----- Server state
  setSingleRunStatus: Action<ABMModel, RunStatus>;
  setBatchRunStatus: Action<ABMModel, RunStatus>;
  setIsUploading: Action<ABMModel, boolean>;
  // ----- Graphing
  setGraphModelKey: Action<ABMModel, string>;
  setGraphAgentKey: Action<ABMModel, string>;
}

export const abm: ABMModel = {
  // * State
  // ----- Server state
  canDownloadData: computed(
    (state) =>
      checkCanDownloadDataForStatus(state.singleRunStatus) &&
      checkCanDownloadDataForStatus(state.batchRunStatus),
  ),
  singleRunStatus: { status: "unknown" },
  batchRunStatus: { status: "unknown" },
  isUploading: false,
  // ----- Graphing
  graphModelKey: null,
  graphAgentKey: null,

  // * Actions
  resetStore: action((state) => {
    state.isUploading = false;
    state.graphModelKey = null;
    state.graphAgentKey = null;
  }),
  // ----- Server state
  setSingleRunStatus: action((state, modelExecutionState) => {
    state.singleRunStatus = modelExecutionState;
  }),
  setBatchRunStatus: action((state, modelExecutionState) => {
    state.batchRunStatus = modelExecutionState;
  }),
  setIsUploading: action((state, uploadState) => {
    state.isUploading = uploadState;
  }),
  // ----- Graphing
  setGraphModelKey: action((state, key) => {
    state.graphModelKey = key;
  }),
  setGraphAgentKey: action((state, key) => {
    state.graphAgentKey = key;
  }),

  ...abmStoreThunk,
};
